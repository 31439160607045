<template>
  <div class="model-details-contailer">
    <div
      class="item-li model-container"
      style="overflow:hidden;height: 100vh;width: 100vw;"
    >
      <!-- 正常3d -->
      <div
        style="height: 100vh;width: 100vw;"
        class="model"
        :style="goodsBackground ? 'background: url(' + goodsBackground + ') 50% 50% no-repeat;background-size: 100% auto;background-size: cover;' : 'background: url(../assets/img/media/model_bg_1.png) 50% 50% no-repeat;background-size: 100% auto;'"
      >
        <img
          class="img-close"
          src="../assets/icon/media/close.png"
          alt=""
          @click="goBack()"
        />
        <div
          id="model"
          style="height: 100vh;width: 100vw;"
          :class="{'model-is-loaded': modelIsLoaded,'model-not-loaded': !modelIsLoaded, }"
        ></div>

        <div
          class="row-center"
          style="position: absolute;bottom: 1.625rem;width: 100vw;"
        >
          <div
            class="refresh"
            @click="initRotate"
          >
            <img
              src="../assets/icon/media/refresh.png"
              alt=""
            >
          </div>
        </div>

        <div
          v-show="!modelIsLoaded"
          class="loading"
        >
          <div class="loading-top">
            <img
              src="../assets/icon/media/model_loading.png"
              alt=""
            >
            <p>{{loadingTip}}</p>
          </div>
          <div>
            <van-progress
              :percentage="progress"
              stroke-width="4"
              color="#FFFFFF"
              track-color="#A2A2A2"
              :show-pivot="false"
            />
            <!-- <van-progress
              :percentage="50"
              stroke-width="2"
              color="#A2A2A2"
            /> -->
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { Progress } from 'vant';
import Cookies from "js-cookie";

Vue.use(Progress);
export default {
  data () {
    return {
      modelIsLoaded: false,
      viewer: {},
      loadingTip: '加载中',
      progress: 0,
      model: {},
      modelView: null,
      goodsSrc: '',
      goodsPoster: '',
      goodsSource: '',
      goodsBackground: '',
    }
  },
  mounted () {
    this.goodsSrc = localStorage.getItem('3DGoodsSrc')
    this.goodsPoster = localStorage.getItem('3DGoodsPoster')
    this.goodsSource = localStorage.getItem('3DGoodsSource')
    if (localStorage.getItem('goodsBackground')) {
      this.goodsBackground = localStorage.getItem('goodsBackground')
    }
    this.init()

  },
  beforeDestroy () {
    this.viewer.modelManager && this.viewer.modelManager.remove(this.model)
    if (this.model && this.model.dispose) {
      this.model.dispose()

    }

  },
  methods: {
    init () {
      setTimeout(() => {
        this.viewer = new AMRT.Viewer('model', /*容器dom或者dom对应的id*/ { offline: true })
        this.initProgress()
        this.viewer.loadModel(this.goodsSource.replace('.zip','/'),{
          offline: true,
          focus: true,
          background: false,
          onLoad: (model) => {
            this.model = model
            this.progress = 100


            setTimeout(() => {
              this.modelIsLoaded = true
            },1000)
            this.modelView = this.viewer.controls.getView()
            // this.viewer.controls.fit(this.model)
            this.viewer.fitCamera(this.model)
            this.viewer.sceneManager.scene.background = null
            this.viewer.domElement.style.backgroundColor = ''
            this.viewer.sceneManager.setDirectLightLightness(0.01)
            this.viewer.controls.startAutoRotate(0.8)
            this.viewer.controls.enableZoom = true
            setTimeout(() => {
              this.$store.commit('HIDE_APPLOADING')
            },1000)

            // 以下页面需要判断在模型加载前是否后退
            let routeWhiteList = ['/modelDetails','/compositeRecordDetail','/compositeDetail','/blindDetails','/goodDetails','/management','/seriesCollectionDetails','/artistDetails','/collection']
            // 挂在异常手动清除dom
            setTimeout(() => {
              if (!routeWhiteList.includes(this.$route.path)) {
                this.viewer.modelManager.remove(this.model)
                this.model.dispose()
                var bodyDom = document.getElementsByTagName("body")[0]
                if (bodyDom) {
                  let arr = bodyDom.getElementsByTagName("canvas")
                  for (var i = 0; i < arr.length; i++) {
                    bodyDom.removeChild(arr[i])
                  }
                }
              }
            },100)
          },
          onError: (err) => {
            this.$toast({
              message: '模型初始化失败',
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
      },1000)
    },
    initProgress () {
      this.progress = 1

      const intervalId = setInterval(() => {
        // debugger
        let speed = Math.floor(100 / (1000000 / 65000))
        if (this.tipIndex == 3) {
          this.tipIndex = 0
        }
        this.loadingTip = `加载中${this.tipIndex == 1 ? '.' : this.tipIndex == 2 ? '..' : '...'}`

        this.tipIndex += 1
        // 控制进度条防止在未上传成功时进度条达到100
        if (this.progress < 99 && this.progress + speed < 100) {
          this.progress += speed//控制进度条速度
          // data.onProgress(progress)//onProgress接收一个对象{ percent: 进度 }在进度条上显示
        } else if ((this.progress === 99)) {
          this.progress++
        } else if (this.progress === 100) {
          clearInterval(intervalId)
        }
      },300)
    },
    goBack () {
      if (Cookies.get('Device') == 'android') {
        // webview内返回上级页面
        var arr1 = JSON.parse(localStorage.getItem('fromUrlList')).filter((i,j) => j < (JSON.parse(localStorage.getItem('fromUrlList')).length - 1))
        this.$router.push(JSON.parse(localStorage.getItem('fromUrlList'))[(JSON.parse(localStorage.getItem('fromUrlList'))).length - 1])
        localStorage.setItem('fromUrlList',JSON.stringify(arr1))
      } else {
        // h5返回上级页面
        this.$router.go(-1);
      }
    },
    initRotate () {

      this.viewer.controls.flyTo(this.modelView)
      this.viewer.controls.stopAutoRotate(0)

      setTimeout(() => {
        this.viewer.fitCamera(this.model)
        this.viewer.controls.startAutoRotate(0.8)
      },1000)
    },
  },
}
</script>
<style lang="less" scoped>
.model-details-contailer {
  width: 100vw;
  height: 100vh;
  .img-close {
    width: 2.5rem;
    height: 2.5rem;
    display: block;
    position: fixed;
    top: 1rem;
    left: 1rem;
    right: auto;
    bottom: auto;
    z-index: 9999;
  }
  .goods-item {
    width: 100%;
    font-family: lantingheiweight;

    .item-li {
      width: 100%;
      height: 96vw;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .model-container {
      width: 100%;
      height: 96vw;
      position: relative;
      .model {
        width: 100%;
        height: 100%;
        position: relative;
        .refresh {
          width: 1.9375rem;
          height: 1.9375rem;
        }
        .scale {
          width: 2rem;
          height: 2rem;
          margin-bottom: 10px;
          margin-right: 15px;
        }
      }
      .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 15rem;
        &-top {
          width: 15rem;
          display: flex;
          align-items: center;
          margin-bottom: 1.3125rem;
          justify-content: center;
          img {
            width: 1.875rem;
            height: 1.875rem;
            display: block;
          }
          p {
            margin-left: 0.625rem;
            font-size: 0.875rem;
            color: #ffffff;
          }
        }
        .vant-progress {
          width: 15rem;
        }
      }
      #model {
        width: 100%;
        height: 100%;
      }
      .model-is-loaded {
        visibility: visible;
      }
      .model-not-loaded {
        visibility: hidden;
      }
    }
  }
}
</style>